body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Sora', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: unset;
  height: unset;
}

.ant-picker-input > input {
  color: white !important;  /* Cor do texto da data selecionada */
}